<template>
  <section class="invoice-add-wrapper">
    <!-- Track sale Section -->
    <b-row class="invoice-add">
      <b-col cols="12">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h3 class="text-primary invoice-logo">Vendu</h3>
                  <b-input-group
                    class="input-group-merge invoice-edit-input-group ml-2 disabled"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HashIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-if="project"
                      id="invoice-data-id"
                      v-model="project.code"
                      disabled
                    />
                    <b-form-input
                      v-else
                      id="invoice-data-id"
                      value="----"
                      disabled
                    />
                  </b-input-group>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />
          <b-form @submit.prevent="onSubmit">
            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in trackData.services"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->

                        <!-- Service title col -->
                        <b-col cols="12" lg="4">
                          <label class="d-inline">Service:</label>
                          <b-form-input
                            v-model="item.designation"
                            type="text"
                            class="mb-2"
                            placeholder="Entrer le service"
                          />
                        </b-col>

                        <!-- Cost rate col -->
                        <b-col cols="12" lg="2">
                          <label class="d-inline">Taux Coutant:</label>
                          <b-form-input
                            v-model="item.cost_rate"
                            type="number"
                            class="mb-2"
                            @input="
                              (val) => updateTrackData(index, 'duty_free')
                            "
                          />
                        </b-col>

                        <!-- Unit col -->
                        <b-col cols="12" lg="2">
                          <label class="d-inline">Unité:</label>
                          <b-form-input
                            v-model="item.unit"
                            type="text"
                            class="mb-2"
                          />
                        </b-col>

                        <!-- Quantity col -->
                        <b-col cols="12" lg="2">
                          <label class="d-inline">Qté:</label>
                          <b-form-input
                            v-model="item.qty"
                            type="number"
                            class="mb-2"
                            @input="
                              (val) => updateTrackData(index, 'duty_free')
                            "
                          />
                        </b-col>
                        <b-col cols="12" lg="2">
                          <label class="d-inline">Rentabilité:</label>
                          <b-form-input
                            v-model="item.profitability"
                            type="number"
                            step="0.01"
                            class="mb-2"
                            @input="
                              (val) => updateTrackData(index, 'duty_free')
                            "
                          />
                        </b-col>

                        <!-- Duty free col -->
                        <b-col cols="12" lg="3">
                          <label class="d-inline">HT:</label>
                          <b-input-group>
                            <b-form-input
                              v-model="item.duty_free"
                              type="number"
                              class="mb-0"
                              disabled
                            />
                            <b-input-group-append is-text>
                              XOF
                            </b-input-group-append>
                          </b-input-group>
                        </b-col>

                        <!-- Total col -->
                        <b-col cols="12" lg="3">
                          <label class="d-inline"
                            >Total:
                            <span
                              class="float-right font-italic"
                              v-if="findTax(item.tax)"
                              >{{ findTax(item.tax).label }} :
                              {{ findTax(item.tax).value }} %</span
                            ></label
                          >
                          <b-input-group>
                            <b-form-input
                              v-model="item.total"
                              type="number"
                              class="mb-0"
                              disabled
                            />
                            <b-input-group-append is-text>
                              XOF
                            </b-input-group-append>
                          </b-input-group>
                        </b-col>
                      </b-row>
                      <div
                        class="d-flex flex-column justify-content-between border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />
                        <feather-icon
                          :id="`form-item-settings-icon-${index}`"
                          size="16"
                          icon="SettingsIcon"
                          class="cursor-pointer"
                        />

                        <!-- Setting Item Form -->
                        <b-popover
                          :ref="`form-item-settings-popover-${index}`"
                          :target="`form-item-settings-icon-${index}`"
                          triggers="click"
                          placement="lefttop"
                        >
                          <b-row>
                            <!-- Field: Taxes -->
                            <b-col cols="8">
                              <b-form-group
                                label="Sélectionner Taxe"
                                :label-for="`setting-item-${index}-tax-1`"
                              >
                                <v-select
                                  v-model="item.tax"
                                  :dir="
                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                  "
                                  :options="taxeOptions"
                                  :reduce="(t) => t.slug"
                                  :input-id="`setting-item-${index}-tax`"
                                  :clearable="true"
                                  @input="
                                    (val) => updateTrackData(index, 'tax')
                                  "
                                  placeholder="Selectionner Taxe"
                                >
                                  <template
                                    v-slot:no-options="{ search, searching }"
                                  >
                                    <template v-if="searching">
                                      Pas de résultat pour <em>{{ search }}</em
                                      >.
                                    </template>
                                    <em v-else style="opacity: 0.5"
                                      >Recherche une taxe...</em
                                    >
                                  </template>
                                </v-select>
                              </b-form-group>
                            </b-col>

                            <b-col
                              cols="12"
                              class="d-flex justify-content-between mt-1"
                            >
                              <b-button
                                variant="outline-primary"
                                @click="
                                  () => {
                                    $refs[
                                      `form-item-settings-popover-${index}`
                                    ][0].$emit('close');
                                  }
                                "
                              >
                                Fermer
                              </b-button>
                              <!-- <b-button
                                variant="outline-secondary"
                                @click="
                                  () => {
                                    $refs[
                                      `form-item-settings-popover-${index}`
                                    ][0].$emit('close');
                                  }
                                "
                              >
                                Annuler
                              </b-button> -->
                            </b-col>
                          </b-row>
                        </b-popover>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm()"
              >
                Ajouter Service
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Total -->
                <b-col cols="12" md="6">
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                      class="mt-md-0 my-3 d-flex align-items-center"
                      order="2"
                      order-md="1"
                    >
                      <label class="text-nowrap d-inline mr-50"
                        >Accompte :</label
                      >
                      <b-form-input
                        v-model="trackData.percent_deposit"
                        size="sm"
                        type="number"
                      />
                      <span class="ml-1">%</span>
                    </b-col>
                    <!-- Col: Profit -->
                    <b-col
                      cols="12"
                      md="6"
                      class="mt-md-0 my-3 d-flex align-items-center"
                      order="2"
                      order-md="1"
                    >
                      <label class="text-nowrap d-inline mr-50"
                        >Profit Escompté :</label
                      >
                      <b-form-input
                        v-model="trackData.percent_expected_profit"
                        size="sm"
                        type="number"
                        @input="(val) => setExpectedProfitPrice(val)"
                      />
                      <span class="ml-1">%</span>
                    </b-col>
                    <b-col
                      cols="12"
                      class="mt-md-0 mb-3 d-flex align-items-center"
                      order="2"
                      order-md="1"
                    >
                      <label class="text-nowrap d-inline mr-50"
                        >Profit Escompté :</label
                      >
                      <b-form-input
                        v-model="trackData.price_expected_profit"
                        size="sm"
                        type="number"
                        disabled
                      />
                      <span class="ml-1">XOF</span>
                    </b-col>
                  </b-row></b-col
                >
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-center"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title text-nowrap">Montant HT:</p>
                      <p class="invoice-total-amount text-nowrap">
                        <span class="mx-1">XOF</span> {{ dutiesFree }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title text-nowrap">Taxes:</p>
                      <p class="invoice-total-amount text-nowrap">
                        {{ taxes }} <span class="mx-1">%</span>
                      </p>
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title text-nowrap">Total:</p>
                      <p class="invoice-total-amount text-nowrap">
                        <span class="mx-1">XOF</span>
                        {{ total }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
              <div class="d-flex flex-row justify-content-between mb-75">
                <div>
                  <b-button @click="goBack" variant="warning" size="sm">
                    <feather-icon icon="CornerUpLeftIcon" />
                    Retour
                  </b-button>
                </div>
                <div>
                  <b-button
                    variant="danger"
                    size="sm"
                    type="reset"
                    :disabled="isBtInit || isSubmitting"
                  >
                    <feather-icon icon="XCircleIcon" />
                    Effacer
                  </b-button>
                  <b-button
                    variant="primary"
                    class="ml-3"
                    size="sm"
                    type="submit"
                    :disabled="!trackDataHasAnyServices || isSubmitting"
                  >
                    <feather-icon icon="SaveIcon" />
                    <span v-if="!isBtInit"> Sauvegarder</span>
                    <span v-else> Mettre à jour</span>
                  </b-button>
                  <b-button
                    @click="saveToRealTracking"
                    variant="success"
                    class="ml-3"
                    size="sm"
                    :disabled="!trackDataHasAnyServices || isSubmitting"
                  >
                    <feather-icon icon="CheckCircleIcon" />
                    Passer au Suivi réel
                  </b-button>
                </div>
              </div>
            </b-card-footer>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  computed,
  nextTick,
  onMounted,
  ref,
  ComponentInstance,
  getCurrentInstance,
} from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardFooter,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BPopover,
  VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import axios from "@axios";
import { useRouter } from "@core/utils/utils";

const requestOptions = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

export default {
  name: "BudgetTracking",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardFooter,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BPopover,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: ["id"],
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  mounted() {
    this.fetchProjectBudget();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    addNewItemInItemForm(item = null) {
      this.$refs.form.style.overflow = "hidden";
      if (item == null) {
        this.trackData.services.push(
          JSON.parse(JSON.stringify(this.itemFormBlankItem))
        );
      }

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(index) {
      this.trackData.services.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    saveToRealTracking() {
      this.trackData.status = "réel"; // add statut to trackData to change its status
      let swal = this.$swal;
      swal
        .fire({
          title: "Voulez-vous vraiment passer au suivi réel ?",
          text: "Cette action suppose que tous les services ont bien été vérifiés. Êtes vous sûr ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Passer au Réel",
          cancelButtonText: "Annuler",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          preConfirm: (data) => {
            return this.onSubmit()
              .then((response) => {
                if (response.status != 201) {
                  throw new Error(response.statusText);
                }
                return response;
              })
              .catch((error) => {
                swal.showValidationMessage("Une erreur est survenue.");
              });
          },
          allowOutsideClick: () => !swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            swal.fire({
              icon: "success",
              title: "Réel",
              text: "Vous êtes passé au suivi réel.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const toast = useToast();
    const { route, router } = useRouter();
    const isSubmitting = ref(false);
    const project = computed(() => trackData.value.project);
    const isBtInit = computed(() => trackData.value.hasOwnProperty("uid"));

    const fetchProjectBudget = () => {
      store
        .dispatch("project/fetchBudget", { id: props.id })
        .then(({ resource, message }) => {
          toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
          if (
            resource.hasOwnProperty("uid") &&
            resource.hasOwnProperty("services")
          ) {
            trackData.value = resource;
            if (isBtInit.value) {
              resource.services.forEach((service, i) => {
                proxy.addNewItemInItemForm(service);
                updateTrackData(i, "duty_free");
              });
              proxy.initTrHeight();
            }
          } else {
            trackData.value.project = resource.project;
          }
        })
        .catch((error) => {
          //
        });
    };

    const findTax = (itemTax) =>
      taxeOptions.value.find((t) => t.slug === itemTax);

    const dutiesFree = ref(null); // HT
    const taxes = ref(null); // Total taxes
    const total = ref(null); // TTC
    const form = ref(null); // form

    const trackDataHasAnyServices = computed(() => {
      if (
        trackData.value.services.length > 0 &&
        trackData.value.services[0].designation &&
        trackData.value.services[0].duty_free
      )
        return true;
      else if (trackData.value.services.length > 1) return true;
      else return false;
    });
    const updateTrackData = (i, attr) => {
      let services = trackData.value.services;

      // 1-/ Add duty free to total duties free if user enter duty free (HT) on single service
      if (attr == "duty_free") {
        const cr = checkNumber(services[i].cost_rate);
        const qty = checkNumber(services[i].qty);
        const profit = checkNumber(services[i].profitability);

        services[i].duty_free =
          parseFloat(cr) * parseFloat(qty) * parseFloat(profit);
        dutiesFree.value = services.reduce((acc, curr) => {
          return acc + parseFloat(curr[attr]);
        }, 0);
      }

      // Add tax on total taxes if user choose tax on a single service
      if (attr == "tax") {
        let selectedTaxes = []; // use this array to get unique taxe slug

        taxes.value = services.reduce((acc, curr) => {
          if (!selectedTaxes.includes(curr.tax)) {
            let currTax = findTax(curr.tax);

            if (currTax != undefined) {
              selectedTaxes.push(currTax.slug);
              return acc + parseFloat(currTax?.value);
            }
            return acc;
          }
          return acc;
        }, 0);
      }

      // 2-/ get current service total
      const df = checkNumber(services[i].duty_free); // Get current service duty free
      const t = findTax(services[i].tax); // Get current service taxe
      let taxCalc = 0;
      if (t != undefined)
        taxCalc = (parseFloat(df) * parseFloat(checkNumber(t?.value, 1))) / 100; // calculate taxe to add on current service duty free

      services[i].total = parseFloat(df) + taxCalc;

      // 3-/ get all services total
      total.value = services.reduce((acc, curr) => {
        return acc + parseFloat(curr.total);
      }, 0);
    };

    const setExpectedProfitPrice = (val) => {
      trackData.value.price_expected_profit =
        (val * (dutiesFree.value != null ? dutiesFree.value : 0)) / 100;
    };

    const checkNumber = (num, rtnVal) => {
      if (!rtnVal) rtnVal = 0;
      if (num !== null && num !== "" && num !== undefined) return num;
      return rtnVal;
    };

    //** we'll be handle from api soon, taxes we'll be fetched
    const taxeOptions = ref([
      {
        label: "TVA",
        slug: "tva",
        value: 18,
      },
      {
        label: "AIB",
        slug: "aib",
        value: 3,
      },
    ]);

    const itemFormBlankItem = {
      designation: null,
      cost_rate: null,
      unit: null,
      qty: null,
      profitability: null,
      total: null,
      duty_free: null,
      tax: "",
    };

    const trackData = ref({
      price_expected_profit: null,
      percent_expected_profit: null,
      percent_deposit: null,
      project: null,

      // ? Set single Item in form for adding data
      services: [JSON.parse(JSON.stringify(itemFormBlankItem))],
    });

    const goBack = () => router.go(-1);
    const onSubmit = () => {
      isSubmitting.value = true;
      if (isBtInit.value) {
        return updateBudgetTracking();
      } else {
        return addBudgetTracking();
      }
    };

    const addBudgetTracking = () => {
      isSubmitting.value = true;
      return axios
        .post(
          `/finances/projects/${project.value.uid}/trackings`,
          trackData.value,
          requestOptions
        )
        .then((response) => {
          isSubmitting.value = false;
          router.go(-1);
          toast({
            component: ToastificationContent,
            props: {
              title: "Le vendu a été enregistré avec succès",
              icon: "CopyIcon",
              variant: "success",
            },
          });
          return response;
        })
        .catch((error) => {
          isSubmitting.value = false;
          if (error.response.data?.errors) {
            const errors = error.response.data?.errors;
            for (const property in errors) {
              if (Object.hasOwnProperty.call(errors, property)) {
                const messages = errors[property].join();
                toast({
                  component: ToastificationContent,
                  props: {
                    title: messages,
                    icon: "CopyIcon",
                    variant: "danger",
                  },
                });
              }
            }
          }
          return error;
        });
    };
    const updateBudgetTracking = () => {
      isSubmitting.value = true;
      return axios
        .put(
          `/finances/trackings/${trackData.value.uid}`,
          trackData.value,
          requestOptions
        )
        .then((response) => {
          isSubmitting.value = false;
          router.go(-1);
          toast({
            component: ToastificationContent,
            props: {
              title: "Le vendu a été modifié avec succès",
              icon: "CopyIcon",
              variant: "success",
            },
          });
          return response;
        })
        .catch((error) => {
          isSubmitting.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Une erreur est survenue lors de la modification du vendu",
              icon: "CopyIcon",
              variant: "danger",
            },
          });
          return error;
        });
    };

    return {
      // option
      taxeOptions,

      // data
      isBtInit,
      isSubmitting,
      trackData,
      trackDataHasAnyServices,
      dutiesFree,
      taxes,
      total,
      project,

      // handle
      fetchProjectBudget,
      findTax,
      itemFormBlankItem,
      updateTrackData,
      goBack,
      onSubmit,
      setExpectedProfitPrice,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
